
.standard-navigation{
    position: fixed;
    right: 10px;
    top:5px;
  }

  .btn {
    color: #292b2f;
    background: #FFF;
    padding: 12px 17px;
    width: 150px;
    margin-top: 20px;
    font-family: 'Comfortaa', cursive;
    border: 3px solid #292b2f;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #018185 ;
      -webkit-transition: all ease 0.8s;
      -moz-transition: all ease 0.8s;
      transition: all ease 0.8s;
  }
  .btn:hover {
    box-shadow: inset 150px 0 0 0 #40af93;
    color: #fff;
  }
/* Position and sizing of burger button */
.mobile-navigation {
    display: none;
    font-family: 'Comfortaa', cursive;
}

.mobile-navigation a:link {
    cursor: pointer;
    text-decoration: none;
    color:#40af93;
  }
  
  .mobile-navigation a:visited {
    cursor: pointer;
    color:#40af93;
  }
  
  .mobile-navigation a:hover {
    text-decoration: none;
    cursor: pointer;
    color:#d7d5d9;
  }
  
  .mobile-navigation a:active {
    text-decoration: none;
    color:#40af93;
  }

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 10px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #40af93;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background:  #292b2f;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #018185;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #018185;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  @media only screen and (max-width: 1000px) {
    .btn {
      padding: 5px 8px;
      width: 110px;
    }
  
    .btn:hover {
      box-shadow: inset 150px 0 0 0 #40af93;
    }
  }

  @media only screen and (max-width: 800px) {
    .standard-navigation {
        display: none;
    }

    .mobile-navigation {
        display: block;
    }

  }

  