.about-container {
    background: #018185;
    width: 100%;
    height: 75%;
    position: fixed;
    bottom: 50px;
    font-family: 'Comfortaa', cursive;
    font-size: 1.5rem;
}

.about-p-container {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.about-p-container p {
    padding: 25px;
}

.section1-greeting {
    font-size: 2.5rem;
    position: fixed;
    bottom: 42px;
    right: 50px;
    color: #d7d5d9;
    text-align: right;
}
@media only screen and (min-height: 1000px) {

    .about-container {
    font-size: 2rem;
    }
  }

@media only screen and (max-width: 600px) {

    .about-container {
    font-size: 1rem;
    }

    .about-p-container p {
        padding: 10px;
    }
  }
  @media only screen and (max-width: 350px) {
    .about-container {
        font-size: .95rem;
        }
    .about-p-container p {
        padding: 5px;
    }
    .section1-greeting {
        font-size: 1.2rem;
        position: fixed;
        bottom: 47px;
        right: 10px;
        color: #d7d5d9;
        text-align: right;
    }

  }